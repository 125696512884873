<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="审核状态" prop="auditStatus" >
        <a-radio-group v-model="form.auditStatus" button-style="solid">
          <a-radio-button v-for="(d, index) in isStatusOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.auditStatus == 2" label="审核状态" prop="auditReason" >
        <a-textarea v-model="form.auditReason" show-count :maxlength="50" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getReport, addReport, updateReport } from '@/api/user/report'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        userId: null,

        targetUserId: null,

        dataType: null,

        dataId: null,

        reportType: null,

        reportImg: null,

        reportContent: null,

        auditStatus: 0,

        auditReason: null

      },
      isStatusOptions:[{
        label: '驳回',
        value: 2
      },{
        label: '通过',
        value: 1
      }],
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '举报人用户id, 外键{zb_user.id}不能为空', trigger: 'blur' }
        ],
        targetUserId: [
          { required: true, message: '被举报用户id, 外键{zb_user.id}不能为空', trigger: 'blur' }
        ],
        dataType: [
          { required: true, message: '数据类型, 1-举报用户, 2-举报商品不能为空', trigger: 'change' }
        ],
        dataId: [
          { required: true, message: '数据id, 以 data_type 为准, 1-用户id, 2-商品id不能为空', trigger: 'blur' }
        ],
        reportType: [
          { required: true, message: '举报类型, 外键{zb_report_type.id}不能为空', trigger: 'change' }
        ],
        auditStatus: [
          { required: true, message: '审核状态, 0-未审核, 1-审核成功, 2-审核失败, 默认-0不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        userId: null,
        targetUserId: null,
        dataType: null,
        dataId: null,
        reportType: null,
        reportImg: null,
        reportContent: null,
        auditStatus: 0,
        auditReason: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getReport({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateReport(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addReport(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
